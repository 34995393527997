import React from "react"

import {
  makeStyles,
  SvgIcon,
} from "@material-ui/core"

function Triangle(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 610 16" preserveAspectRatio="none">
      <filter id="dropshadow" height="150%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
        <feOffset dx="3" dy="3" result="offsetblur"/>
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.33"/>
        </feComponentTransfer>
        <feMerge> 
          <feMergeNode/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
      <polygon points="610,16 610,0 0,0" style={{filter:'url(#dropshadow)'}} />
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  none: {
    display: 'none',
  },
  topRight: {
    width: '100%',
    height: 'auto',
    transform: 'scaleX(-1)',
    display: 'block',
    margin: '-1px 0',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
    overflow: 'visible',
  },
  bottomRight: {
    width: '100%',
    height: 'auto',
    transform: 'scaleX(-1) scaleY(-1)',
    display: 'block',
    margin: '-1px 0',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 99,
    overflow: 'visible',
  },
  topLeft: {
    width: '100%',
    height: 'auto',
    transform: 'scaleY(-1)',
    display: 'block',
    margin: '-1px 0',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
    overflow: 'visible',
  },
  bottomLeft: {
    width: '100%',
    height: 'auto',
    transform: 'scaleY(-1)',
    display: 'block',
    margin: '-1px 0',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 99,
    overflow: 'visible',
  },
  bottomRightNav: {
    width: '100%',
    height: 'auto',
    transform: 'scaleX(-1)',
    display: 'block',
    margin: '-1px 0 -2.25%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 99,
    overflow: 'visible',
    [theme.breakpoints.up('sm')]: {
      margin: '-1px 0 -2.6%',
    },
  },
}));


const Border = ({ type, fill }) => {
  const classes = useStyles();
  let borderClass = classes.none;
  const fillColor = fill || '#FFF';

  if (type === 'topRight') {
    borderClass = classes.topRight
  }
  
  if (type === 'bottomRight') {
    borderClass = classes.bottomRight
  }

  if (type === 'topLeft') {
    borderClass = classes.topLeft
  }

  if (type === 'bottomLeft') {
    borderClass = classes.bottomLeft
  }

  if (type === 'bottomRightNav') {
    borderClass = classes.bottomRightNav
  }

  return (
    <Triangle className={borderClass} style={{fill: fillColor}} />
  )
  
}


export default Border
