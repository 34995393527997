import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { createMuiTheme } from '@material-ui/core/styles'


const breakpoints = createBreakpoints({})



const headingsFontFamily = "Montserrat, Helvetica, Arial, sans-serif"
const bodyFontFamily = "Istok Web, Helvetica, Arial, sans-serif"


const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#da5b22',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#343434',
        contrastText: '#FFF',
      },
      background: {
        default: '#FFF',
        alternate: '#EBEBEB',
      },
    },
    typography: {
      fontFamily: bodyFontFamily,
      fontSize: 16,
      fontWeightLight: 400,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      h1: {
        fontFamily: headingsFontFamily,
        fontWeight: 700,
        fontSize: "1.5em",
        lineHeight: 1.167,
        letterSpacing: 0,
        [breakpoints.up('sm')]: {
          fontSize: "1.85rem",
        },
        [breakpoints.up('md')]: {
          fontSize: "3rem",
        },
        [breakpoints.up('lg')]: {
          fontSize: "4rem",
        },
      },
      h2: {
        fontFamily: headingsFontFamily,
        fontWeight: 700,
        fontSize: "1.5em",
        lineHeight: 1.167,
        letterSpacing: 0,
        [breakpoints.up('sm')]: {
          fontSize: "1.85rem",
        },
        [breakpoints.up('md')]: {
          fontSize: "3rem",
        },
      },
      h3: {
        fontFamily: headingsFontFamily,
        fontWeight: 700,
        fontSize: "1.325em",
        lineHeight: 1.167,
        letterSpacing: 0,
        [breakpoints.up('sm')]: {
          fontSize: "1.75rem",
        },
        [breakpoints.up('md')]: {
          fontSize: "2.5rem",
        },
      },
      h4: {
        fontFamily: headingsFontFamily,
        fontWeight: 700,
        fontSize: "1.125em",
        lineHeight: 1.167,
        letterSpacing: 0,
        [breakpoints.up('sm')]: {
          fontSize: "2.5rem",
        },
      },
      h5: {
        fontFamily: headingsFontFamily,
        fontWeight: 700,
        fontSize: "1em",
        lineHeight: 1.167,
        letterSpacing: 0,
        [breakpoints.up('sm')]: {
          fontSize: "2rem",
        },
      },
      h6: {
        fontFamily: headingsFontFamily,
        fontWeight: 700,
        fontSize: "1em",
        lineHeight: 1.167,
        letterSpacing: 0,
        [breakpoints.up('sm')]: {
          fontSize: "1.5rem",
        },
      },
      subtitle1: {
        fontWeight: 700,
      },
      body1: {
        fontSize: '0.875rem',
        [breakpoints.up('sm')]: {
          fontSize: "1rem",
        },
      },
      body2: {
        fontSize: '0.875rem',
        [breakpoints.up('sm')]: {
          fontSize: "1rem",
        },
      },
    }
  });

export default theme;
