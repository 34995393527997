import React from "react"

import Border from '../components/border'

import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core"

import Logo from '../images/acquireinteractive-logo.svg'
import Background01 from '../images/hero.jpg'


const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#FFF',
    textAlign: 'center',
    position: 'relative',
    zIndex: 100,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  logo: {
    maxWidth: '100px',
    height: '28px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '180px',
      height: '52px',
    },
  },
  link: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '0.733rem',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    padding: '0 0.5em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      padding: '0 1em',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  background:{
    backgroundImage:'url(' + Background01 + ')',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'relative',
    zIndex: 99,
  },
  backgroundOverlay: {
    background: 'rgba(0,0,0,0.66)',
    textShadow: '2px 4px 3px rgba(0,0,0,0.66)',
  },
  appBar: {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    '&> .MuiBox-root': {
      backgroundColor: '#FFFFFF',
      position: 'relative',
      zIndex: 100,
    }
  },
  button01: {
    backgroundColor: '#396a97',
    boxShadow: '0 10px 8px -8px rgba(0,0,0,0.99)',
    color: '#FFF',
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
  
    textShadow: '1px 2px 1px rgba(0,0,0,0.66)',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
    '&:hover': {
      backgroundColor: '#264663',
    },
  },
  button02: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 10px 8px -8px rgba(0,0,0,0.99)',
    color: '#FFF',
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    textShadow: '1px 2px 1px rgba(0,0,0,0.66)',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
    '&:hover': {
      backgroundColor: 'rgb(152, 63, 23)',
    },
  },
}));


const Header = ({ headline }) => {
  const classes = useStyles();
  return (
    <>
    <AppBar
      position="sticky"
      className={classes.appBar}
    >
      <Box>
        <Container
        maxWidth="lg"
        >
          <Box paddingY={1} display="flex" alignContent="center" justifyContent="space-between">
            <Box component="img" src={Logo} alt="Acquire Interactive" className={classes.logo} />
            <Box display="flex" alignItems="center">
              <Link 
                href="#affiliates" 
                className={classes.link}
                underline="none"
              >
                Sell Leads
              </Link>
              <Link 
                href="#lenders" 
                className={classes.link}
                underline="none"
              >
                Buy Leads
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
      <Border type="bottomRightNav" />
    </AppBar>
    <Box 
      className={classes.header}
      >
      </Box>
      <Container   
        maxWidth={false}
        disableGutters
      >
        <Box
          bgcolor="secondary.main"
          color="secondary.contrastText"
          position="relative"
          className={classes.background}
        >
          <Box className={classes.backgroundOverlay}>
            
            <Container maxWidth="lg">
                <Box paddingY={{xs: 18, md: 36}}>
                  
                  <Typography
                      variant="h3"
                      align="center"
                  >
                    THE
                  </Typography>
                  <Typography
                      variant="h1"
                      align="center"
                  >

                    ACCELERATED MARKETPLACE<br />
                  </Typography>
                  <Typography
                      variant="h5"
                      align="center"
                  >
                    <em>&ldquo;AMP&rdquo;</em>
                  </Typography>
                  <Box paddingY={2} />
                  <Container maxWidth="sm">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          href="#affiliates" 
                          className={classes.button01}
                          variant="contained"
                          fullWidth
                        >
                          SELL LEADS
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          href="#lenders" 
                          className={classes.button02}
                          variant="contained"
                          fullWidth
                        >
                          BUY LEADS
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
            </Container>
            <Border type="bottomRight" />
          </Box>
        </Box>
      </Container>
      </>
  )
  
}

export default Header
