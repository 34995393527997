/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import theme from '../styles/theme'
import Header from "./header"

import {
  Box,
  Container,
  CssBaseline,
  Link,
  ThemeProvider,
  Typography,
} from "@material-ui/core"


const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        <Header />
        {children}
        <Box
          bgcolor="secondary.main"
          color="secondary.contrastText"
          position="relative"
          style={{zIndex: 100}}
        >
          <Container component="footer" maxWidth="lg">
            <Box
              paddingY={2}
            >
              <Typography variant="body2" align="center">
                © {new Date().getFullYear()} <Link color="inherit" href="https://www.acquireinteractive.com/">Acquire Interactive, LLC</Link>
              </Typography>
            </Box>
            
          </Container>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
